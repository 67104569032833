body {
  margin: 0;
}

.app {
  background-color: #1d1d20;
  width: 100%;
  min-height: 100vh;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  
  height: 50px;
  display: flex;

  margin-right: 6px;

  align-items: center;
}

.share-button {
  cursor: pointer;
  opacity: 1.0;

  margin-right: 6px;

  transition: opacity 0.25s ease-in-out;
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}